import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  MdBackup,
  MdAssignment,
  MdBuild,
  MdLanguage,
  MdKeyboard,
} from "react-icons/md"
import { Button } from "react-bootstrap"
import pdf from "../misc/xhail.pdf"
import {
  FaLinkedin,
  FaRegNewspaper,
  FaTwitterSquare,
  FaRegHandshake,
} from "react-icons/fa"
import { AiOutlineStar } from "react-icons/ai"
import { MdWork } from "react-icons/md"
import axios from "axios"
import XHAIL from "../components/forms/xhail"
import { GiCpu } from "react-icons/gi"

const xhailHero = require("../images/apprenticeship.jpeg")
const unconventional = require("../images/unconventional.jpeg")
const unconventional2 = require("../images/unconventional2.jpeg")
const diverseteam = require("../images/diverseteam.jpeg")
const diverseteam2 = require("../images/diverseteam2.jpeg")
const femalenetwork = require("../images/femalenetwork.jpeg")

const hostUrl = process.env.GATSBY_BACKEND_HOST

const ApprenticeshipPage = () => {
  const [newsLetter, setNewsLetter] = useState({
    active: false,
    email: "",
    success: false,
    error: false,
    submitted: false,
  })

  // Handles the application modal
  const [applicationOpen, setApplicationOpen] = useState(false)

  // Dynamically changes modal description/title based on selected role
  const [selectedRole, setSelectedRole] = useState({
    title: "IT Project Manager",
  })

  const subscribeToNewsLetter = () => {
    axios
      .post(`${hostUrl}/xhail-newsletter/add-contact`, {
        email: newsLetter.email,
      })
      .then(res => {
        console.log(res)
        setNewsLetter({ ...newsLetter, success: true, submitted: true })
      })
      .catch(err => {
        console.log(err)
        setNewsLetter({ ...newsLetter, error: true, submitted: true })
      })
  }

  return (
    <Layout>
      <SEO title="xHAIL" meta={[]} lang="en" description="" />
      <XHAIL
        open={applicationOpen}
        handleClose={() => setApplicationOpen(false)}
        selectedRole={selectedRole}
      />
      {/* Header/Hero Section for xHAIL */}
      <section
        className="full p-5 d-flex align-items-center"
        style={{
          backgroundImage: `url(${xhailHero})`,
          backgroundSize: "cover",
          backgroundPosition: "left center",
        }}
      >
        <div style={{ maxWidth: "1200px" }}>
          <h1 className="mb-3">Flapmax xHAIL Apprenticeship Program​</h1>

          <h2 style={{ maxWidth: "1000px" }}>
            xHAIL is a global apprenticeship program from Flapmax. Its mission
            is to recruit, train on-the-job, and empower underrepresented talent
            for inclusion in the modern workforce.​
          </h2>

          <div>
            <Button
              variant="dark"
              style={{ marginRight: "24px" }}
              className="my-4 mx-sm-5"
              size="lg"
              as="a"
              href={pdf}
              target="_blank"
            >
              Learn more
            </Button>
            <Button
              className="my-4 mx-sm-5"
              variant="secondary"
              size="lg"
              as="a"
              href="#available-roles"
            >
              Explore Roles
            </Button>
          </div>
        </div>
      </section>

      {/* Second Section */}
      {/* Flexbox over container to add side-content */}
      <div className="d-flex justify-content-center">
        {/* Side Content */}
        <div
          className="py-3 d-none d-lg-flex flex-column justify-content-around align-items-center"
          style={{
            width: "250px",
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(251,252,253,1) 50%, rgba(255,255,255,1) 100%)",
          }}
        >
          <h3 className="m-0">X</h3>
          <h2 className="m-0" style={{ color: "#00B056" }}>
            H
          </h2>
          <h2 className="m-0" style={{ color: "#476EC0" }}>
            A
          </h2>
          <h2 className="m-0" style={{ color: "#476EC0" }}>
            I
          </h2>
          <h2 className="m-0" style={{ color: "#FFC12C" }}>
            L
          </h2>
        </div>

        {/* Middle Content */}
        <section className="full">
          <h2 className="text-center title-spacer">
            Establishing new tech careers on-the-job
          </h2>

          <div className="container ">
            {/* First row */}
            <div className="row title-spacer justify-content-center">
              <div className="col-lg-6">
                <h3>
                  Hiring unconventional talent does not necessarily mean hiring
                  persons without a degree.
                </h3>
                <p>
                  In the majority of cases, these are persons from other
                  academic fields who have developed an interest in the
                  technology field. Unconventional talent also includes persons
                  with certifications in specific technologies, for example,
                  cisco in network security technology.​
                </p>
              </div>
              <div className="col-lg-3 d-flex justify-content-center align-items-center">
                <img className="img-fluid" src={unconventional2} alt="" />
              </div>
            </div>

            {/* Second row */}
            <div className="row justify-content-center flex-invert-md">
              <div className="col-lg-3 d-flex justify-content-center align-items-center">
                <img className="img-fluid" src={femalenetwork} alt="" />
              </div>
              <div className="col-lg-6">
                <h3>
                  Flapmax xHAIL apprenticeship program is designed to meet and
                  develop unconventional talent.
                </h3>
                <p>
                  xHAIL finds unconventional talent through different guiding
                  factors including an expanded talent search and acquisition
                  program. The program seeks to enable different participants
                  from both local communities and across the globe to be able to
                  grow and learn on the job. ​​
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Side Content */}
        <div
          className="py-3 d-none  d-lg-flex flex-column justify-content-around align-items-center"
          style={{
            width: "250px",
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(251,252,253,1) 50%, rgba(255,255,255,1) 100%)",
          }}
        >
          <h3 className="m-0">X</h3>
          <h2 className="m-0" style={{ color: "#00B056" }}>
            H
          </h2>
          <h2 className="m-0" style={{ color: "#476EC0" }}>
            A
          </h2>
          <h2 className="m-0" style={{ color: "#476EC0" }}>
            I
          </h2>
          <h2 className="m-0" style={{ color: "#FFC12C" }}>
            L
          </h2>
        </div>
      </div>

      {/* Third Section */}
      <section className="full bg-darkened">
        <h2 className="text-center title-spacer">A purpose-driven mission</h2>

        {/* Flexbox over container to add side-content */}
        <div className="d-flex justify-content-center align-items-stretch">
          <div />
          <div className="container mx-0">
            {/* First Row  */}
            <div className="row my-5">
              <div className="col-lg-4">
                <p className="text-center">
                  <img className="img-fluid" src={unconventional} alt="" />
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Helping unconventional talent​</h3>
                <p>
                  Tackling unemployment requires one to tap into all spheres of
                  talent and skills, from both traditional and non-traditional
                  academic institutions ​
                </p>
                <p>
                  xHAIL is incorporating methodologies to evaluate skills and
                  talents specific to a job description​.
                </p>
              </div>
            </div>

            {/* Second Row  */}
            <div className="row my-5">
              <div className="col-lg-4">
                <p className="text-center">
                  <img className="img-fluid" src={diverseteam} alt="" />
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Combating low employment rate​</h3>
                <p>
                  Unemployment has over the years been fueled by industry hiring
                  practices that have led to diminishing returns amongst
                  different companies thereby lacking the capacity to expand​.
                  ​​
                </p>
                <p>
                  xHAIL has been designed to reach the underrepresented,​
                  provide tutelage and inclusion in critical profile building
                  projects​.
                </p>
              </div>
            </div>
            {/* Third Row  */}
            <div className="row my-5">
              <div className="col-lg-4">
                <p className="text-center">
                  <img className="img-fluid" src={diverseteam2} alt="" />
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Diversity and inclusion​</h3>
                <p>
                  Different cultural backgrounds influence the different
                  understanding of a problem statement as well as different
                  applicable solutions to the same​. ​​
                </p>
                <p>
                  xHAIL strives to ensure that all its members are always
                  learning both on the job and through certification programs.
                </p>
              </div>
            </div>
          </div>
          {/* Side bullet points */}
          <div className="d-lg-flex d-none flex-column align-items-center justify-content-center ">
            <p className="text-white m-0 bullet-item">
              <AiOutlineStar style={{ height: "30px", width: "30px" }} />
            </p>
            <span className="bullet-connection" />
            <p className="text-white m-0 bullet-item">
              <MdWork style={{ height: "30px", width: "30px" }} />
            </p>
            <span className="bullet-connection" />
            <p className="text-white m-0 bullet-item">
              <FaRegHandshake style={{ height: "30px", width: "30px" }} />
            </p>
          </div>
        </div>
      </section>

      {/* Fourth Section - Positions Available */}
      <section id="available-roles">
        <h2 className="text-center title-spacer">
          Available Apprenticeship Roles
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({ title: "IT Project Manager" })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <MdAssignment />
                </h1>
                <h3 className="text-center mb-3">IT Project Manager</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({
                    title: "Cloud Operations Specialist / Architect",
                  })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <MdBackup />
                </h1>
                <h3 className="text-center mb-3">
                  Cloud Operations Specialist / Architect{" "}
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({ title: "Application / Software Developer" })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <MdKeyboard />
                </h1>
                <h3 className="text-center mb-3">
                  Application / Software Developer
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({ title: "IT Generalist" })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <MdLanguage />
                </h1>
                <h3 className="text-center mb-3">IT Generalist</h3>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({ title: "Computer Support Specialist" })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <MdBuild />
                </h1>
                <h3 className="text-center mb-3">
                  Computer Support Specialist
                </h3>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div
                className="card my-3 clickable"
                onClick={() => {
                  setSelectedRole({ title: "Hardware Design & Development" })
                  setApplicationOpen(true)
                }}
              >
                <h1 className="text-center">
                  <GiCpu />
                </h1>
                <h3 className="text-center mb-3">
                  Hardware Design &amp; Development
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Final Section - Social links */}
      <section className="bg-darkened">
        <div className="container">
          <h3>Stay Connected</h3>
          <p>
            Follow us to stay up to date about the XHAIL program and to learn
            more about related opportunities. ​
          </p>
          <div className="d-flex">
            <a
              className="clickable-icon"
              href="https://www.linkedin.com/company/flapmax/"
              target="_blank"
            >
              <FaLinkedin size={36} style={{ marginRight: "16px" }} />
            </a>
            <a href="https://twitter.com/flapmaxhq" target="_blank">
              <FaTwitterSquare
                size={36}
                style={{ marginRight: "16px" }}
                className="clickable-icon"
              />
            </a>
            <a>
              <FaRegNewspaper
                size={36}
                style={{ marginRight: "16px" }}
                className="clickable-icon"
                onClick={() =>
                  setNewsLetter({ ...newsLetter, active: !newsLetter.active })
                }
              />
            </a>
          </div>

          {/* Newsletter Signup */}
          {newsLetter.active && (
            <div className="mt-3">
              <h5>Subscribe to our Newsletter</h5>
              <div className="d-flex">
                <input
                  placeholder="Enter email"
                  value={newsLetter.email}
                  onChange={e =>
                    setNewsLetter({ ...newsLetter, email: e.target.value })
                  }
                />
                {/* Rendering different buttons depending on subscription result */}
                {newsLetter.submitted && newsLetter.error && (
                  <Button variant="danger" size="sm" className="mx-2">
                    Error
                  </Button>
                )}
                {newsLetter.submitted && newsLetter.success && (
                  <Button variant="success" size="sm" className="mx-2">
                    Success
                  </Button>
                )}
                {!newsLetter.submitted && (
                  <Button
                    size="sm"
                    className="mx-2"
                    onClick={() => subscribeToNewsLetter()}
                  >
                    Subscribe
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}
export default ApprenticeshipPage
